<template>
    <div>
        <el-carousel :interval="5000">
            <!-- <el-carousel-item>
                <div class="index-top color-white" :style="{ backgroundImage: 'url(' + banner[0] + ')' }" />
            </el-carousel-item>
            <el-carousel-item>
                <div class="index-top color-white" :style="{ backgroundImage: 'url(' + banner[1] + ')' }" />
            </el-carousel-item> -->
            <el-carousel-item>
                <div class="index-top color-white d-flex" :style="{ backgroundImage: 'url(' + banner[2] + ')', alignItems: 'center' }">
                    <div class="content-box" style="padding-left: 60px">
                        <h1 class="pt-20">{{ $t('home.title1') }}</h1>
                        <h2 class="pb-20" v-html="$t('home.title2')" />
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="box-shadow bg-white">
            <div class=" d-flex-center notice-bar p-20 mb-30">
                <div v-for="(item, index) in noticeList" :key="index">
                    <el-link type='primary' :title="item.title" class="link" @click="$nav.push('/notice/details/'+item.id)">{{ item.title }}</el-link>
                    <span class="mx-20">/</span>
                </div>
                <div>
                    <el-link type="primary" class="link" @click="$nav.push('/notice')">{{ $t('home.more') }} >></el-link>
                </div>
            </div>
        </div>
        <div class="content-box bg-white box-shadow mb-30">
            <!-- <div class="d-flex-center p-16">
                <div class="font-16 mr-auto">Market</div>
            </div> -->
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="font-14 color-gray px-20 py-15">
                <el-col :span='5'>{{ $t('home.pair') }}</el-col>
                <el-col :span='3'>{{ $t('home.price') }}</el-col>
                <el-col :span='4'>{{ $t('home.change') }}</el-col>
                <el-col :span='3'>24H {{ $t('home.high') }}</el-col>
                <el-col :span='3'>24H {{ $t('home.low') }}</el-col>
                <el-col :span='3'>24H {{ $t('home.finished') }}</el-col>
                <el-col class="text-right" :span='3'>{{ $t('home.deal') }}</el-col>
            </el-row>
            <el-row v-for="(item, index) in market" :key="index" :gutter="20" type="flex" justify="center" align="middle" class="market-body px-20 py-15 border-top">
                <el-col :span='1'>
                    <div v-if="item.logo" class="icon icon-style-yuan icon-stars" :style="{ backgroundImage: 'url('+item.logo+')' }" />
                    <div v-else class="icon icon-style-yuan icon-stars" :class="'icon-coin-'+item.coin.toLowerCase()" />
                </el-col>
                <el-col :span='4'><span class="is-link color-p-h" @click="$nav.push('/market/'+item.coin)">{{ item.coin }} / USDT</span></el-col>
                <el-col :span='3'>{{ item.close }}</el-col>
                <el-col :span='4' class="d-flex-align-center">
                    <el-tag v-if="item.change > 0" class="change" type="success" effect="dark" size="medium">+{{ item.change.toFixed(2) }}%</el-tag>
                    <el-tag v-else-if="item.change == 0" class="change" type="warning" effect="dark" size="medium">0.00%</el-tag>
                    <el-tag v-else class="change" type="danger" effect="dark" size="medium">{{ item.change.toFixed(2) }}%</el-tag>
                    <i class="el-icon-top ml-5" :class="{ 'd-none': !item.showUp }" />
                    <i class="el-icon-bottom ml-5" :class="{ 'd-none': !item.showDown }" />
                </el-col>
                <el-col :span='3'>{{ item.high }}</el-col>
                <el-col :span='3'>{{ item.low }}</el-col>
                <el-col :span='3'>{{ item.vol }}</el-col>
                <el-col class="deal text-right" :span='3'>
                    <el-button type="primary" size="small" plain class="bg-t" icon="el-icon-s-data" @click="$nav.push('/market/'+item.coin)">{{ $t('home.trade') }}</el-button>
                </el-col>
            </el-row>
            <div v-if="market.length < 1" v-loading="true" element-loading-background="#00000000" class="p-50" />
        </div>
        <div class="content-box services">
            <div class="content-bg py-30">
                <h2 class="text-center pb-30 color-333">{{ $t('home.service_title') }}</h2>
                <el-row :gutter="20" type="flex" justify="center" align="top">
                    <el-col :span="6">
                        <el-card class="item text-center">
                            <div>
                                <img src="@/assets/index/index-icon-service1.gif" alt="service icon">
                            </div>
                            <h4>{{ $t('home.service1.title') }}</h4>
                            <div style="min-height: 100px;">
                                <p>{{ $t('home.service1.des1') }}</p>
                                <p>{{ $t('home.service1.des2') }}</p>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card class="item text-center">
                            <div>
                                <img src="@/assets/index/index-icon-service2.gif" alt="service icon">
                            </div>
                            <h4>{{ $t('home.service2.title') }}</h4>
                            <div style="min-height: 100px;">
                                <p>{{ $t('home.service2.des1') }}</p>
                                <p>{{ $t('home.service2.des2') }}</p>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card class="item text-center">
                            <div>
                                <img src="@/assets/index/index-icon-service3.gif" alt="service icon">
                            </div>
                            <h4>{{ $t('home.service3.title') }}</h4>
                            <div style="min-height: 100px;">
                                <p>{{ $t('home.service3.des1') }}</p>
                                <p>{{ $t('home.service3.des2') }}</p>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6">
                        <el-card class="item text-center">
                            <div>
                                <img src="@/assets/index/index-icon-service4.gif" alt="service icon">
                            </div>
                            <h4>{{ $t('home.service4.title') }}</h4>
                            <div style="min-height: 100px;">
                                <p>{{ $t('home.service4.des1') }}</p>
                                <p>{{ $t('home.service4.des2') }}</p>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import coinLogo from '@/assets/coin/logo'

export default {
    data() {
        return {
            banner: this.$t('home.banner'),
            coinLogo,
            coin: this.$store.state.marketCoin,
            noticeList: this.$store.state.noticeList ? JSON.parse(this.$store.state.noticeList) : [],
            market: this.$store.state.indexMarket ? JSON.parse(this.$store.state.indexMarket) : [],
            showUp: false,
            showDown: false,
            loading: false,
            timer: null,
            dataVersion: 0
        }
    },
    watch: {
        coin: function () {
            this.market = []
            if (['BTC','ETH','USDT'].indexOf(this.coin)<0) return 
            this.$store.commit('marketCoin', this.coin)
            this.tabChange(true)
        }
    },
    created: function () {
        this.fatchNotice()
        this.tabChange(true)
        this.timer = window.setInterval(() => {
            if (document.hidden) return
            if (['BTC','ETH','USDT'].indexOf(this.coin)<0) return 
            this.tabChange(false)
        }, 2000)
    },
    beforeDestroy: function () {
        window.clearInterval(this.timer)
    },
    methods: {
        fatchNotice: async function (){
            const { data } = await this.$request.get('notice/top')
            this.$store.commit('noticeList', data)
        },
        onTab: function (active) {
            this.coin = active
        },
        tabChange: function (ignoreLoadingStatus = false) {
            if (this.loading && !ignoreLoadingStatus) return
            this.dataVersion++
            let version = Number(this.dataVersion)
            this.loading = true
            this.$requestNotState.get('index/market').then(({ data }) => {
                this.loading = false
                if(version != this.dataVersion) {
                    return
                }
                this.$store.commit('indexMarket', data)
                if(this.market.length > 0){
                    for(const index in data){
                        if(this.market[index]){
                            if(data[index].close > this.market[index].close){
                                data[index].showUp = true
                            }else if(data[index].close < this.market[index].close){
                                data[index].showDown = true
                            }
                        }
                    }
                }
                this.market = data
                window.setTimeout(() => {
                    for(const item of this.market){
                        item.showUp = false
                        item.showDown = false
                    }
                }, 600)
            }).catch(() => {
                this.loading = false
            })
        }
        // onClick: function (item) {
        //     this.$nav.push('/trade/index/'+item.pair+'/'+item.coin)
        // }
    }
}
</script>
<style scoped>
.index-top{
    /* background-image: url(~@/assets/index/top-bg.png); */
    height: 300px;
    background-repeat: no-repeat;
    background-size: auto 460px;
    background-position: center;
}
.icon-style-yuan{
    border-radius: 50%;
}
</style>
<style lang="scss" scoped>
.notice-bar{
    .link{
        display: inline-block;
        white-space: nowrap;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.tabs{
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $bgColor;
    .tab{
        padding-bottom: 18px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
    }
    .active{
        color: $--color-primary;
        border-bottom: 2px solid $--color-primary;
    }
}
.market-body{
    .change{
        font-size: 14px !important;
        width: 80px;
        text-align: center !important;
    }
}
.services{
    .item{
        img{
            width: 100px;
            height: 100px;
        }
        p{
            font-size: 14px;
        }
    }
}
</style>